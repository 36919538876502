import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

declare global {
  interface Window {
    ENV: {
      API_KEY: string;
      AUTH_DOMAIN: string;
      PROJECT_ID: string;
      STORAGE_BUCKET: string;
      MESSAGING_SENDER_ID: string;
      APP_ID: string;
      MEASUREMENT_ID: string;
      DATABASE_URL: string;
      ENVIRONMENT: "production" | "staging";
    };
  }
}

type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  databaseURL: string;
  measurementId?: string;
};

const firebaseConfig = {
  apiKey: window.ENV.API_KEY,
  authDomain: window.ENV.AUTH_DOMAIN,
  projectId: window.ENV.PROJECT_ID,
  storageBucket: window.ENV.STORAGE_BUCKET,
  messagingSenderId: window.ENV.MESSAGING_SENDER_ID,
  appId: window.ENV.APP_ID,
  databaseURL: window.ENV.DATABASE_URL,
} as FirebaseConfig;

const isProduction = firebaseConfig.projectId === "vasco-connect-mobile";

if (isProduction) {
  firebaseConfig.measurementId = window.ENV.MEASUREMENT_ID;
}

const firebase = initializeApp(firebaseConfig);

export const auth = getAuth(firebase);
export const firestore = getFirestore(firebase, "vasco-audience");
export const functions = getFunctions(firebase, isProduction ? "europe-west3" : "europe-west1");
export const rtdb = getDatabase(firebase, firebaseConfig.databaseURL);
